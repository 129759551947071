import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { TpaWorkerFactory } from './tpaWorker'
import { TpaWorkerSymbol } from './symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler, TpaWorkerSymbol).to(TpaWorkerFactory)
}

export * from './types'
export { TpaWorkerSymbol }
